import React, {Fragment} from "react";
import Field from "./Fields/Field";
import CountryField from "./Fields/CountryField";
import {usePage} from "../../../../Provider/PageProvider";
import PagesApi from "../../../../Service/PagesApiService";
import {useLang} from "../../../../Provider/LangProvider";
import StateField from "./Fields/StateField";

const isTeeth = () => {
    return window.location.host === '-checkout.myteethacademy.com';
}

const texts: any = {
    en: {
        step3: "Step 3",
        deliveryAddress: isTeeth() ? "DIPLOMA MAILING ADDRESS" : "Delivery Address",
        streetAddress: "Street Address",
        zip: "Zip / Postal Code",
        state: "State / Province",
        city: "Town / City",
    },
    es: {
        step3: "PASO 3",
        deliveryAddress: "DIRECCIÓN DE ENTREGA",
        streetAddress: "Dirección",
        zip: "Código postal",
        state: "Estado / provincia",
        city: "Estado / provincia",
    },
    de: {
        step3: "SCHRITT 3",
        deliveryAddress: "LIEFERADRESSE",
        streetAddress: "Straße",
        zip: "Postleitzahl",
        state: "Staat / Provinz",
        city: "Stadt",
    },
}

interface Props {
    address?: any,
    onAddressChange?: (key: string, val: string) => void,
    customHeaderText?: any,
    disableInit?: boolean,
    customSubHeaderText?: any,
}

export default function DeliveryAddress(props: Props) {
    const {address, onAddressChange, url} = usePage();
    const {lang} = useLang();

    const formAddress = props.address ? props.address : address;
    const onFormAddressChange = props.onAddressChange ? props.onAddressChange : onAddressChange;

    if (!formAddress) {
        return <Fragment/>
    }

    const onFieldBlur = () => {
        PagesApi.checkZip(formAddress['zip'], formAddress['country']).then(res => {
            if ('state' in res && 'city' in res && !!res.state && !!res.city) {
                onAddressChange('multiple', JSON.stringify(res));
            }
        })
    }

    return (
        <Fragment>
            <h2>{props.customHeaderText ? props.customHeaderText :
                <Fragment>{texts[lang].step3}: <span>{texts[lang].deliveryAddress}</span></Fragment>}</h2>

            {!!props.customSubHeaderText && <p>{props.customSubHeaderText}</p>}

            <CountryField val={formAddress.country} onAddressChange={props.onAddressChange} disableInit={props.disableInit}/>
            <Field val={formAddress.street} onChange={onFormAddressChange} title={texts[lang].streetAddress}
                   fieldKey={"street"}
                   required={true}/>

            <div className="double a">
                <Field val={formAddress.zip} onChange={onFormAddressChange} onFieldBlur={onFieldBlur}
                       title={texts[lang].zip}
                       fieldKey={"zip"} required={true}/>

                {/*{url === 'lucky-test' ? <StateField/> :*/}
                {/*    <Field val={address.state} onChange={onAddressChange} title={texts[lang].state} fieldKey={"state"}*/}
                {/*           required={true}/>}*/}
                <StateField address={props.address} onAddressChange={props.onAddressChange}/>

            </div>

            <Field val={formAddress.city} onChange={onFormAddressChange} title={texts[lang].city} fieldKey={"city"}
                   required={true}/>
        </Fragment>
    )
}
