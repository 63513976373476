import React, {Fragment} from "react";
import {usePage} from "../../../../../Provider/PageProvider";
import {CartTotalItem} from "../../../../../Types/Objects";
import {useLang} from "../../../../../Provider/LangProvider";
import DiscountCoupon from "./DiscountCoupon";

const isLuckytail = () => {
    return window.location.host === 'checkout.luckytail.com';
}

interface Props {
    type?: string;
}

const texts: any = {
    en: {
        item: "Item",
        amount: "Amount",
        total: "Total"
    },
    es: {
        item: "Artículo",
        amount: "Cantidad",
        total: "Total"
    },
    de: {
        item: "Artikel",
        amount: "Anzahl",
        total: "Summe"
    },
}

export default function ListTotal({type}: Props) {
    const {contact, onContactChange, url} = usePage();

    let ulClassName = ["list-total"];
    if (type) {
        ulClassName.push("overlay-" + type);
    }

    const {cartItems, cartTotal, currencySymbol, discountMode} = usePage();
    const {lang} = useLang();

    const hasTax = cartItems.filter(i => i.title.indexOf('Estimated Sales Tax') >= 0).length > 0;
    const _cartItems = cartItems.filter(i => i.title.indexOf('Estimated Sales Tax') === -1);
    const _total = _cartItems.map(item => item.price * item.quantity).reduce((a: number, b: number) => a + b, 0)

    return (
        <ul className={ulClassName.join(' ')}>
            <li className="header">{texts[lang].item} <span>{texts[lang].total}</span></li>
            {_cartItems.map((item: CartTotalItem, index: number) => {
                return <li key={"cart-key-" + index}>{item.quantity > 1 &&
                    <Fragment>{item.quantity}x </Fragment>}{item.title}
                    <span>{currencySymbol}{(item.price * item.quantity).toFixed(2)}</span></li>
            })}

            {discountMode && <DiscountCoupon/>}


            <li className="strong">
                {texts[lang].total}{hasTax &&
                <i style={{fontSize: 12, color: '#AAA', marginLeft: 4, fontStyle: 'normal', fontWeight: 500}}>(State tax
                    may apply)</i>}
                <span>{currencySymbol}{_total.toFixed(2)}</span>
            </li>
            {!!contact && isLuckytail() &&
            <li>
                <label className={"newsletter-row"}>
                    <input type={"checkbox"} className={'newsletter-checkbox'} checked={contact.newsletter} onChange={() => onContactChange('newsletter', !contact.newsletter)}/>
                    <p className={"newsletter-text"}>
                        Yes, I would also like to sign up for the weekly newsletter.
                    </p>
                </label>
            </li>
            }
        </ul>
    )
}
