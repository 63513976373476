import React, {useContext, useEffect, useState} from "react";
import {Product, UpsaleCartTotalItem} from "../Types/Objects";
import {useDidMount} from "../Hooks/UseDidMount";

interface ProviderValue {
    cartItems: UpsaleCartTotalItem[],
    addCartItem: (product: Product, quantity: number) => void,
    removeFromCartItem: (product: Product) => void,
    changeCartItemQuantity: (quantity: number, productId: number) => void,
}


export const CartContext = React.createContext<ProviderValue>({
    cartItems: [],
    addCartItem: (product: Product, quantity: number) => {
    },
    removeFromCartItem: (product: Product) => {
    },
    changeCartItemQuantity: (quantity: number, productId: number) => {
    },
});

export const useCart = () => useContext(CartContext);

interface Props {
    children: React.ReactChild
}

export const CartProvider = (props: Props) => {
    const isMount = useDidMount();

    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('amazonCheckoutSessionId');

    let checkoutCacheObj: any = undefined;
    try {
        // const checkoutCache = window.localStorage.getItem('checkoutCacheUpsale');
        // if (checkoutCache) {
        //     checkoutCacheObj = JSON.parse(checkoutCache);
        // }
    } catch (e) {
        console.log('checkoutCache', e);
    }

    const [cartItems, setCartItems] = useState<UpsaleCartTotalItem[]>(!!checkoutCacheObj && !!checkoutCacheObj.cartItems ? checkoutCacheObj.cartItems : []);


    useEffect(() => {
        if (!isMount) {
            const cache = {
                cartItems,
            }
            window.localStorage.setItem('checkoutCacheUpsale', JSON.stringify(cache));
        }
    }, [cartItems,]);

    const addCartItem = (product: Product, quantity: number) => {
        setCartItems([...cartItems, {product, quantity}]);
    }

    const removeFromCartItem = (product: Product) => {
        setCartItems([...cartItems.filter((p: UpsaleCartTotalItem) => p.product.productId !== product.productId)]);
    }

    const changeCartItemQuantity = (quantity: number, productId: number) => {
        setCartItems([...cartItems.map((p: UpsaleCartTotalItem) => {
            if (productId === p.product.productId) {
                return {...p, quantity};
            } else {
                return p;
            }
        })]);
    }

    return (
        <CartContext.Provider value={{
            cartItems,
            addCartItem,
            removeFromCartItem,
            changeCartItemQuantity,
        }}>
            {props.children}
        </CartContext.Provider>
    )
};
