import React, {useEffect} from "react";

import {Countries} from "../../../../../Constants/Countries";
import FormsApi from "../../../../../Service/FormsApiService";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

interface Props {
    val: string;
    onAddressChange?: (key: string, val: string) => void,
    disableInit?: boolean
}

interface Country {
    name: string;
    code: string;
}

const texts: any = {
    en: {
        country: "Country"
    },
    es: {
        country: "País"
    },
    de: {
        country: "Staat"
    },
}


export default function CountryField(props: Props) {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('amazonCheckoutSessionId');

    const {val} = props;

    const {onAddressChange} = usePage();
    const {lang} = useLang();

    const onFormAddressChange = props.onAddressChange ? props.onAddressChange : onAddressChange;

    const init = () => {
        if (!props.disableInit && !sessionId) {
            FormsApi.init().then(res => {
                if (res.country) {
                    const address = {
                        'country': res.country,
                        'city': res.city,
                        'state': res.state,
                    }
                    onFormAddressChange('multiple', JSON.stringify(address));
                }
            });
        }
    }

    useEffect(init, [props.disableInit]);

    return <p className={"focus"}>
        <label htmlFor="country">{texts[lang].country}</label>
        <select value={val} onChange={(e) => onFormAddressChange('country', e.target.value)} id={"country"}>
            {Countries.map((item: Country) => {
                return (<option key={'country-' + item.code} value={item.code}>{item.name}</option>)
            })}
        </select>

    </p>
}
