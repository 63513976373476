import React from "react";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";

const isTeeth = () => {
    return window.location.host === '-checkout.myteethacademy.com';
}
const isDogfasting = () => {
    return window.location.host === 'checkout.dogfasting.com';
}

const isLulaSafe = () => {
    return window.location.host === 'checkout.lulasafe.com';
}

const isSecureBlessify = () => {
    return window.location.host === 'secure.blessify.com';
}

interface Props {

}

const texts: any = {
    en: {
        congrats: "CONGRATS!",
        qualify: isTeeth() ? "You Qualify For Free Diploma" : (isDogfasting() ? "You Qualify For Free Smart Dog Leash" : "You Qualify For Free Shipping"),
        addExtra: isTeeth() || isDogfasting() ? "Upgrade to Annual plan" : (isLulaSafe() ? "Add Extra Magnetic Keys" : (isSecureBlessify()?"Upgrade Order to 4 Crosses":"Add Extra Grinding Heads")),
        toUnlock: isTeeth() ? "and get a FREE Diploma!" : (isDogfasting() ? "and get a FREE Smart Dog Leash!" : "To Unlock Free Shipping")
    },
    es: {
        congrats: "¡FELICIDADES!",
        qualify: "Cumples los requisitos de envío gratuito.",
        addExtra: "Añade cabezales de limado adicionales para",
        toUnlock: "desbloquear el envío gratis"
    },
    de: {
        congrats: "GLÜCKWUNSCH!",
        qualify: "Sie qualifizieren sich für den kostenlosen Versand ",
        addExtra: "Fügen Sie zusätzliche Schleifköpfe hinzu,",
        toUnlock: "um den kostenlosen Versand freizuschalten"
    },
}

export default function ShippingContainerV2(props: Props) {
    const {upgradeShippingProducts, selectedProduct, cartTotalWoShipping, url, currencySymbol} = usePage();
    const {lang} = useLang();
    const items = upgradeShippingProducts.filter(f => {
        return f.triggerTotal > cartTotalWoShipping;
    });

    if (!items || items.length === 0) {
        return (
            <div className={"shipping-v2"}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="#1BAF30"/>
                    <path d="M21.9998 9L13.8164 17.0909L10.0004 13.3347L7 16.289L13.8164 23L25 11.9544L21.9998 9Z"
                          fill="white"/>
                </svg>

                <span>{texts[lang].congrats}<br/>
                    {texts[lang].qualify}</span>
            </div>
        )
    }

    const left = items[0].triggerTotal - cartTotalWoShipping;
    const leftPercent = Math.max(0, Math.min(100, (100 - left / items[0].triggerTotal * 100)));


    if (url === 'regular-dog-claw-grinding-device-single-extra') {
        return (
            <div className="shipping-bar-wrapper">
                <div id="shipping-bar-content">
                    <span id="shippingText" style={{display: "block"}}>
                        {`You're ${currencySymbol}${left.toFixed(2)} away from free shipping!`}
                    </span>


                    <div className="meter animate">

                        <span style={{width: `${leftPercent}%`}}><span></span></span>
                        <div id="shipping-bar-img">
                            <img style={{maxWidth: 55, marginTop: -20, marginLeft: -3}}
                                 src="https://luckytail.com/wp-content/uploads/2022/05/free_shipping_slider1.png"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"shipping-v2 red"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#F06565"/>
                <line x1="11" y1="11" x2="21" y2="21" style={{stroke: "#fff", strokeWidth: 3}}/>
                <line x1="21" y1="11" x2="11" y2="21" style={{stroke: "#fff", strokeWidth: 3}}/>
            </svg>
            <span>
                {texts[lang].addExtra}<br/>
                {texts[lang].toUnlock}
            </span>
        </div>
    )
}
