import React, {useEffect} from "react";
import AmazonPayButton from "./AmazonPayButton";

export default function AmazonPayRedirect() {


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get('amazonCheckoutSessionId');

        // @ts-ignore
        window.top.onAmazonCheckoutGet(sessionId);

    }, []);


    return (
        <AmazonPayButton/>
    )
}
