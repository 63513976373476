import React, {Fragment, useEffect, useState} from "react";

import {useParams} from 'react-router-dom'
import PagesApi from "../../../../../Service/PagesApiService";
import {DetailsResponse} from "../../../../../Types/Upsale";
// @ts-ignore
import getSymbolFromCurrency from 'currency-symbol-map'
import {Address, Contact} from "../../../../../Types/CheckoutTypes";
import {Document, Image, Link, Page, PDFViewer, StyleSheet, Text, View} from '@react-pdf/renderer';

const isSecureBlessify = () => {
    return window.location.host === 'secure.blessify.com';
}
const isLulaSafe = () => {
    return window.location.host === 'checkout.lulasafe.com';
}

const texts: any = {
    en: {
        youJustGot: "You Just Got A Great Deal!",
        orderReceipt: "Order receipt",
        yourProduct: "Your Product Receipt",
        item: "Item",
        amount: "Amount",
        total: "Total",
        thankYou: "Thank You for Your Order!",
        confMail: "A confirmation email with more information will also be sent to your email address!",
        confMail2: "P.S. If you don't see anything in your inbox, please don't forget to check your junk/spam folder.",
        confMail3: "We hope you love your",
        confMail4: "however if you’re not completely satisfied, please reach out to us via email and we will assist you!",
        confMail5: "THANK YOU: #PRODUCTNAME# has OVER 300,000 HAPPPY CUSTOMERS WORLDWIDE and counting!",
        gotQuestions: "Got Questions?",
        needAssistance: "Need assistance regarding your order or anything about our products?",
        feelFree: "Feel free to email us",
        assistant: "We are more than willing to assistant you"
    },
    es: {
        youJustGot: "¡Acabas de conseguir una gran oferta!",
        orderReceipt: "Recibo de pedido",
        yourProduct: "Recibo de tu producto",
        item: "Artículo",
        amount: "Cantidad",
        total: "Total",
        thankYou: "¡Gracias por tu pedido!",
        confMail: "¡Enviaremos también una carta de confirmación, con más información, a tu dirección de correo electrónico!",
        confMail2: "PD: Si no lo recibes en tu bandeja de entrada, no olvides revisar tu carpeta de correo no deseado.",
        confMail3: "Esperamos que te guste tu limadora de uñas indolora para mascotas",
        confMail4: " Sin embargo, si no quedas completamente satisfecho, comunícate con nosotros por correo electrónico y te ayudaremos.",
        confMail5: "GRACIAS: ¡Llegamos a más de 50.000 clientes satisfechos en junio de 2020!",
        gotQuestions: "¿Tienes preguntas?",
        needAssistance: "¿Necesitas ayuda con respecto a tu pedido o a alguno de nuestros productos?",
        feelFree: "No dudes en enviarnos un correo electrónico a",
        assistant: "Estamos más que dispuestos a ayudarte."

    },

    de: {
        youJustGot: "Sie haben gerade einen guten Deal gemacht!",
        orderReceipt: "Bestellbeleg",
        yourProduct: "Ihre Quittung/ Rechnung",
        item: "Artikel",
        amount: "Anzahl",
        total: "Gesamt",
        thankYou: "Vielen Dank für Ihre Bestellung!",
        confMail: "Eine Bestätigungs-E-Mail mit weiteren Informationen wird ebenfalls an Ihre E-Mail-Adresse gesendet!",
        confMail2: "P.S. Wenn Sie nichts in Ihrem Posteingang sehen, vergessen Sie bitte nicht, Ihren Junk- / Spam-Ordner zu überprüfen.",
        confMail3: "Wir hoffen, dass Sie Ihren",
        confMail4: "Wenn Sie jedoch nicht vollständig zufrieden sind, wenden Sie sich bitte per E-Mail an uns und wir helfen Ihnen weiter!",
        confMail5: "DANKE: Wir erreichten im Juni 2020 über 50.000 zufriedene Kunden!",
        gotQuestions: "Gibt es Fragen?",
        needAssistance: "Benötigen Sie Unterstützung bei Ihrer Bestellung oder Informationen zu unseren Produkten?",
        feelFree: "Bitte senden Sie uns eine E-Mail an",
        assistant: "Wir helfen Ihnen gerne weiter."
    },
}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        flexDirection: 'column',
        padding: 20,
        // backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    headerDetails: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    textHeader: {
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 14,
        marginBottom: 5,
    },
    text: {
        fontWeight: 400,
        fontSize: 12,
    },
    textTableHeader: {
        color: '#595959',
        fontSize: 14,
    },
    textTableBody: {
        color: '#000',
        fontSize: 12,
    },
    textSectionTitle: {
        fontSize: 16,
        fontWeight: 600
    }
});

export default function ConfirmPagePdf() {
    const [contact, setContact] = useState<Contact>();
    const [address, setAddress] = useState<Address>();

    const {serial} = useParams();
    const [data, setData] = useState<DetailsResponse>();

    const getDetails = () => {
        PagesApi.getDetails(serial).then((res: DetailsResponse) => {
            if (res.success === 1) {
                setData(res);
                // console.log('res', res);
                PagesApi.setFavicon(res.data.favicon);

                setTimeout(() => {
                    const event = new Event('checkError');
                    document.dispatchEvent(event);
                }, 500);
            }
        })
    }

    useEffect(() => {
        getDetails();
    }, [serial]);

    const onPageLoad = () => {
        if (data && data.data) {

            if (data.data.shipping) {
                setAddress({
                    city: data.data.shipping.city,
                    state: data.data.shipping.state,
                    country: data.data.shipping.country,
                    zip: data.data.shipping.postal_code,
                    street: data.data.shipping.line1,
                })
            }
            if (data.data.contact) {
                const name = data.data.contact.name.split(" ");
                setContact({
                    firstName: name[0],
                    lastName: name[1],
                    email: data.data.contact.email,
                    phone: data.data.contact.phone,
                })
            }
        }
    }
    useEffect(onPageLoad, [data]);

    if (!data) {
        return <Fragment/>;
    }

    const currencySymbol = getSymbolFromCurrency(data.data.currency);

    const totalItems = data.items.filter(p => p.title.indexOf('Sales Tax') === -1);
    const taxItems = data.items.filter(p => p.title.indexOf('Sales Tax') !== -1);
    let taxTotal = 0;
    let taxTitle = '';
    taxItems.forEach(l => {
        taxTitle = l.title.replace('Estimated ', '')
        taxTotal += l.price * l.quantity;
    })

    const isOther = !isLulaSafe() && !isSecureBlessify();

    return (
        <PDFViewer style={{width: '100vw', height: '100vh'}}>
            <Document>
                <Page size="A4" style={styles.page}>

                    <View style={{height: 150}}>
                        <View style={{...styles.header}}>
                            <View>

                                {isLulaSafe() && <Image src={"/images/lulasafelogo.png"}
                                                              style={{height: 56, width: 218}}/>}

                                {isSecureBlessify() && <Image src={"/images/Group-3831.png"}
                                                              style={{height: 59, width: 189}}/>}

                                {isOther && <Image src={"/images/luckytail_logo_black.jpg"}
                                                   style={{height: 75, width: 150}}/>
                                }

                            </View>
                            <View style={{flexGrow: 1}}>
                            </View>
                            <View>
                                <Text style={{fontSize: 30}}>
                                    INVOICE
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{height: 130}}>
                        <View style={styles.headerDetails}>
                            <View>
                                <Text style={styles.textHeader}>Bill to:</Text>
                                <Text style={styles.text}>{contact?.firstName} {contact?.lastName}</Text>
                                <Text
                                    style={styles.text}>{address?.street}, {address?.state}, {address?.country}</Text>
                                <Text style={styles.text}>{contact?.phone}</Text>
                                <Text style={styles.text}>{contact?.email}</Text>
                            </View>
                            <View style={{flexGrow: 1}}>

                            </View>
                            <View>
                                <Text style={styles.textHeader}>Invoice id:</Text>
                                <Text style={styles.text}>{data.data.orderPrefix}-{serial.toUpperCase()}</Text>
                                <Text>&nbsp;</Text>
                                <Text style={styles.textHeader}>Order date:</Text>
                                <Text style={styles.text}>{data.data.orderDate}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.textSectionTitle}>Your order</Text>

                        <View style={{display: "flex", flexDirection: 'row', marginTop: 20,}}>
                            <Text style={styles.textTableHeader}>Item</Text>
                            <View style={{flexGrow: 1}}>
                            </View>
                            <Text style={styles.textTableHeader}>Amount</Text>
                        </View>
                        {totalItems.map((item, index) => {
                            const q = !!item.quantity && item.quantity > 1 ? item.quantity + ' x ' : '';
                            return (
                                <View style={{display: "flex", flexDirection: 'row', marginTop: 10}}
                                      key={"cart-" + index}>
                                    <Text style={styles.textTableBody}>{item.title}</Text>
                                    <View style={{flexGrow: 1}}>
                                    </View>
                                    <Text
                                        style={styles.textTableBody}>{q}{currencySymbol}{item.price.toFixed(2)}</Text>
                                </View>
                            )

                        })}
                        <View style={{marginTop: 10, marginBottom: 5, backgroundColor: '#EEE', height: 1}}>

                        </View>
                        {taxTotal > 0 &&
                            <Fragment>
                                <View style={{display: "flex", flexDirection: 'row', marginTop: 5}}>
                                    <Text style={styles.textTableHeader}>{taxTitle}</Text>
                                    <View style={{flexGrow: 1}}>
                                    </View>
                                    <Text
                                        style={styles.textTableHeader}>{currencySymbol}{(taxTotal).toFixed(2)}</Text>
                                </View>
                                <View
                                    style={{marginTop: 10, marginBottom: 5, backgroundColor: '#EEE', height: 1}}></View>
                            </Fragment>

                        }
                        <View style={{display: "flex", flexDirection: 'row', marginTop: 5}}>
                            <Text style={styles.textTableHeader}>Total</Text>
                            <View style={{flexGrow: 1}}>
                            </View>
                            <Text
                                style={styles.textTableHeader}>{currencySymbol}{(data.total / 100).toFixed(2)}</Text>
                        </View>

                        <Text style={{...styles.textSectionTitle, marginTop: 40}}>GOT QUESTIONS?</Text>
                        <Text style={{marginTop: 5, fontSize: 12}}>Need assistance regarding your order or anything
                            about our products? Feel free to:</Text>

                        <View style={{height: 60, marginTop: 20,}}>
                            <View style={{flex: 1, flexDirection: 'row'}}>
                                <View>
                                    <Text style={styles.textHeader}>
                                        VISIT US ON
                                    </Text>
                                    <Text style={{fontSize: 12}}>
                                        {isLulaSafe() && <Link src={"https://lulasafe.com"}>www.lulasafe.com</Link>}

                                        {isSecureBlessify() && <Link src={"https://blessify.com"}>www.blessify.com</Link>}

                                        {isOther && <Link src={"https://www.luckytail.com"}>www.luckytail.com</Link>}
                                    </Text>
                                </View>
                                <View style={{flexGrow: 1}}>
                                </View>
                                <View>
                                    <Text style={styles.textHeader}>
                                        EMAIL US AT
                                    </Text>
                                    <Text style={{fontSize: 12}}>
                                        {isLulaSafe() &&
                                            <Link src={"mailto:hello@lulasafe.com"}>hello@lulasafe.com</Link> }

                                        {isSecureBlessify() &&
                                            <Link src={"mailto:hello@blessify.com"}>hello@blessify.com</Link> }
                                        {isOther &&
                                            <Link src={"mailto:hello@luckytail.com"}>hello@luckytail.com</Link>}
                                    </Text>
                                </View>
                                <View style={{flexGrow: 1}}>
                                </View>
                                <View>
                                    <Text style={styles.textHeader}>
                                        FIND US ON
                                    </Text>
                                    <Text style={{fontSize: 12}}>
                                        {isLulaSafe() &&
                                            <Fragment>
                                                <Link
                                                    src={"https://www.facebook.com/lulasafe"}>facebook</Link> or <Link
                                                src={"https://www.instagram.com/lulasafe.official/"}>Instagram</Link> or <Link
                                                src={"https://www.tiktok.com/@lulasafe"}>TikTok</Link>
                                            </Fragment> }

                                        {isSecureBlessify() &&
                                            <Fragment>
                                                <Link
                                                    src={"https://www.facebook.com/blessifyapp"}>facebook</Link> or <Link
                                                src={"https://www.instagram.com/blessifyapp/"}>Instagram</Link>
                                            </Fragment> }

                                        {isOther &&
                                            <Fragment>
                                                <Link
                                                    src={"https://www.facebook.com/yourluckytail/"}>facebook</Link> or <Link
                                                src={"https://www.instagram.com/yourluckytail/?hl=en"}>Instagram</Link>
                                            </Fragment>
                                        }
                                    </Text>
                                </View>
                            </View>
                        </View>

                    </View>
                    <View style={{flexGrow: 1}}>

                    </View>
                    <View style={{borderTop: '1px solid #EEE', paddingTop: 10}}>
                        <Text style={{fontSize: 10, color: "#CCC"}}>
                            {data.data.is_n === 0 ? "Upexi Pet Products, LLC, 1710 Whitney Mesa Dr, Henderson, NV 89014, United States" : (isLulaSafe()?"Moonway LLC, 8 The Green, Suite # 11188, Dover, DE 19901, United States":"GA Solutions LLC, 2035 Sunset Lake Road, Suite B-2, Newark, DE 19702")}

                        </Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
    //
    // return (
    //     <LangProvider lang={data.data.lang}>
    //         <div style={{padding: 20}}>
    //             <div style={{display: 'flex', alignItems: 'center'}}>
    //                 <img src={data.data.mainLogoUrl} style={{height: 80, maxHeight: 80}}/>
    //                 <span style={{flexGrow: 1}}>&nbsp;</span>
    //                 <p style={{fontSize: 28, textAlign: "right"}}>INVOICE</p>
    //             </div>
    //
    //             <div style={{display: 'flex'}}>
    //                 <div>
    //                     <div style={{fontSize: 16, fontWeight: 600, textTransform: 'uppercase'}}>Bill to:</div>
    //                     <p>
    //                         {contact?.firstName} {contact?.lastName}<br/>
    //                         {address?.street}, {address?.state}, {address?.country}<br/>
    //                         {contact?.phone}<br/>
    //                         {contact?.email}
    //                     </p>
    //                 </div>
    //                 <span style={{flexGrow: 1}}>&nbsp;</span>
    //                 <div>
    //                     <div style={{fontSize: 16, fontWeight: 600, textTransform: 'uppercase'}}>Invoice id:</div>
    //                     <p>{data.data.orderPrefix}-{serial.toUpperCase()}</p>
    //
    //                     <div style={{fontSize: 16, fontWeight: 600, textTransform: 'uppercase'}}>Order date:</div>
    //                     {/*<p>{data.data.}</p>*/}
    //                 </div>
    //             </div>
    //             <div>
    //                 <p style={{fontSize: 30 ,fontWeight: 600}}>Your order</p>
    //                 <div style={{padding: 10}}>
    //                     <ul className="list-total">
    //                         <li className="header" >{texts[lang].item} <span>{texts[lang].amount}</span></li>
    //                         {data.items.map((item, index) => {
    //                             const q = !!item.quantity && item.quantity > 1 ? item.quantity + ' x ' : '';
    //                             return (<li key={"item-" + index}>{item.title}
    //                                 <span>{q}{currencySymbol}{item.price.toFixed(2)}</span>
    //                             </li>);
    //                         })}
    //                         <li className="strong">{texts[lang].total}
    //                             <span>{currencySymbol}{(data.total / 100).toFixed(2)}</span></li>
    //                     </ul>
    //                 </div>
    //             </div>
    //             <div>
    //                 <h3>{texts[lang].gotQuestions}</h3>
    //                 <p>{texts[lang].needAssistance}<br/>{texts[lang].feelFree} {data.data.email}. {texts[lang].assistant}
    //                 </p>
    //             </div>
    //
    //             <hr/>
    //             <div style={{fontSize: 12, color: "#AAA"}}>
    //                 GA Solutions LLC, 2035 Sunset Lake Road, Suite B-2, Newark, DE 19702
    //             </div>
    //         </div>
    //     </LangProvider>
    // )
}
