import React, {useEffect} from "react";
import PagesApi from "../../../../../../Service/PagesApiService";
import {useParams} from "react-router-dom";
import {isMobileOnly} from 'react-device-detect';

interface Props {
    url?: string,
    upsaleOrder?: string
}

export default function AmazonPayButton(props: Props) {
    const {url} = useParams();

    const urlToButton = props.url ? props.url : url;

    useEffect(() => {
        PagesApi.generateButtonAmazon(urlToButton, isMobileOnly, props.upsaleOrder).then(res => displayButton(res.data));
    }, [urlToButton, props.upsaleOrder]);

    const displayButton = (d: any) => {
        // @ts-ignore
        window.amazon.Pay.renderButton('#AmazonPayButton', {
            // set checkout environment
            merchantId: 'A2ZSYBGVWUDAOR',
            ledgerCurrency: 'USD',
            sandbox: d.sandbox,
            // customize the buyer experience
            checkoutLanguage: 'en_US',
            productType: 'PayOnly',
            placement: 'Checkout',
            buttonColor: 'Gold',
            // configure Create Checkout Session request
            createCheckoutSessionConfig: {
                payloadJSON: d.payload, // string generated in step 2
                signature: d.signature, // signature generated in step 3
                publicKeyId: d.publicKeyId
            }
        });

    }


    return (
        <div id={"AmazonPayButton"} style={{width: '100%', textAlign: 'center'}}>

        </div>
    )
}
