import React, {Fragment, useEffect, useState} from "react";
import EmailImage from "../../../../../Assets/email-us.png";

import {useParams} from 'react-router-dom'
import PagesApi from "../../../../../Service/PagesApiService";
import {DetailsResponse} from "../../../../../Types/Upsale";
import HeaderBackground from "../Header/HeaderBackground";
import HeaderLogoTitle from "../Header/HeaderLogoTitle";
import FooterNeedHelpComponent from "../Footer/FooterNeedHelpComponent";
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {CartTotalItem} from "../../../../../Types/Objects";
// @ts-ignore
import getSymbolFromCurrency from 'currency-symbol-map'
import {LangProvider} from "../../../../../Provider/LangProvider";
import CustomerInformation from "../../Form/CustomerInformation";
import {Address, Contact} from "../../../../../Types/CheckoutTypes";
import DeliveryAddress from "../../Form/DeliveryAddress";

const texts: any = {
    en: {
        youJustGot: "You Just Got A Great Deal!",
        orderReceipt: "Order number",
        yourProduct: "Your Product Receipt",
        item: "Item",
        amount: "Amount",
        total: "Total",
        thankYou: "Thank You for Your Order!",
        confMail: "A confirmation email with more information will also be sent to your email address!",
        confMail2: "P.S. If you don't see anything in your inbox, please don't forget to check your junk/spam folder.",
        confMail3: "We hope you love your",
        confMail4: "however if you’re not completely satisfied, please reach out to us via email and we will assist you!",
        confMail5: "THANK YOU: #PRODUCTNAME# has OVER 300,000 HAPPPY CUSTOMERS WORLDWIDE and counting!",
        gotQuestions: "Got Questions?",
        needAssistance: "Need assistance regarding your order or anything about our products?",
        feelFree: "Feel free to email us",
        assistant: "We are more than willing to assistant you"
    },
    es: {
        youJustGot: "¡Acabas de conseguir una gran oferta!",
        orderReceipt: "Recibo de pedido",
        yourProduct: "Recibo de tu producto",
        item: "Artículo",
        amount: "Cantidad",
        total: "Total",
        thankYou: "¡Gracias por tu pedido!",
        confMail: "¡Enviaremos también una carta de confirmación, con más información, a tu dirección de correo electrónico!",
        confMail2: "PD: Si no lo recibes en tu bandeja de entrada, no olvides revisar tu carpeta de correo no deseado.",
        confMail3: "Esperamos que te guste tu limadora de uñas indolora para mascotas",
        confMail4: " Sin embargo, si no quedas completamente satisfecho, comunícate con nosotros por correo electrónico y te ayudaremos.",
        confMail5: "GRACIAS: ¡Llegamos a más de 50.000 clientes satisfechos en junio de 2020!",
        gotQuestions: "¿Tienes preguntas?",
        needAssistance: "¿Necesitas ayuda con respecto a tu pedido o a alguno de nuestros productos?",
        feelFree: "No dudes en enviarnos un correo electrónico a",
        assistant: "Estamos más que dispuestos a ayudarte."

    },

    de: {
        youJustGot: "Sie haben gerade einen guten Deal gemacht!",
        orderReceipt: "Bestellbeleg",
        yourProduct: "Ihre Quittung/ Rechnung",
        item: "Artikel",
        amount: "Anzahl",
        total: "Gesamt",
        thankYou: "Vielen Dank für Ihre Bestellung!",
        confMail: "Eine Bestätigungs-E-Mail mit weiteren Informationen wird ebenfalls an Ihre E-Mail-Adresse gesendet!",
        confMail2: "P.S. Wenn Sie nichts in Ihrem Posteingang sehen, vergessen Sie bitte nicht, Ihren Junk- / Spam-Ordner zu überprüfen.",
        confMail3: "Wir hoffen, dass Sie Ihren",
        confMail4: "Wenn Sie jedoch nicht vollständig zufrieden sind, wenden Sie sich bitte per E-Mail an uns und wir helfen Ihnen weiter!",
        confMail5: "DANKE: Wir erreichten im Juni 2020 über 50.000 zufriedene Kunden!",
        gotQuestions: "Gibt es Fragen?",
        needAssistance: "Benötigen Sie Unterstützung bei Ihrer Bestellung oder Informationen zu unseren Produkten?",
        feelFree: "Bitte senden Sie uns eine E-Mail an",
        assistant: "Wir helfen Ihnen gerne weiter."
    },
}

export default function ConfirmPage() {
    const {onPageView, registerEvent} = usePageView();

    const [contact, setContact] = useState<Contact>();
    const [address, setAddress] = useState<Address>();

    const [contactUpdated, setContactUpdated] = useState(false);
    const [addressUpdated, setAddressUpdated] = useState(false);

    const onAddressChange = (key: string, val: string) => {
        let addressNew = JSON.parse(JSON.stringify(address));
        if (key === 'multiple') {
            const newData = JSON.parse(val);
            const keys = Object.keys(newData);
            keys.map((_key: string) => {
                addressNew[_key] = newData[_key];
                return key;
            })
        } else {
            // @ts-ignore
            addressNew[key] = val;
        }
        setAddress(addressNew);
    }
    const onContactChange = (key: string, val: string) => {
        let contactNew = JSON.parse(JSON.stringify(contact));
        // @ts-ignore
        contactNew[key] = val;
        setContact(contactNew);
    }

    const {serial} = useParams();
    const [data, setData] = useState<DetailsResponse>();

    const getDetails = () => {
        const lHash = window.location.hash ? window.location.hash : '';
        PagesApi.getDetails(serial, lHash).then((res: DetailsResponse) => {
            if (res.success === 1) {
                setData(res);
                // console.log('res', res);
                PagesApi.setFavicon(res.data.favicon);

                setTimeout(() => {
                    const event = new Event('checkError');
                    document.dispatchEvent(event);
                }, 500);
            }
        })
    }

    useEffect(() => {
        getDetails();
    }, [serial]);

    const onPageLoad = () => {
        if (data && data.data) {

            onPageView({
                allScripts: data.data.allScripts,
                allScriptsUrl: data.data.allScriptsUrl,
                css: data.data.css,
                favicon: data.data.favicon,
                fontUrl: data.data.fontUrl,
                pageScripts: data.data.thankYouScripts,
                pageScriptsUrl: data.data.thankYouScriptsUrl,
                title: data.data.title
            }, 'Confirmation');
            gtagPurchase();

            if (data.data.shipping) {
                setAddress({
                    city: data.data.shipping.city,
                    state: data.data.shipping.state,
                    country: data.data.shipping.country,
                    zip: data.data.shipping.postal_code,
                    street: data.data.shipping.line1,
                })
            }
            if (data.data.contact) {
                const name = data.data.contact.name.split(" ");
                setContact({
                    firstName: name[0],
                    lastName: name[1],
                    email: data.data.contact.email,
                    phone: data.data.contact.phone,
                })
            }
        }
    }
    useEffect(onPageLoad, [data]);

    const gtagPurchase = () => {
        if (!!data && !!data.data) {
            const products = data.items.map((item: CartTotalItem) => {
                return {
                    'id': 'product-' + item.productId,
                    'name': item.title,
                    'price': item.price,
                    'quantity': item.quantity
                }
            })
            try {
                // // @ts-ignore
                // window.gtag('event', 'purchase', {
                //     "transaction_id": serial,
                //     "affiliation": "Checkout",
                //     "value": data.total / 100,
                //     "currency": "USD",
                //     "tax": 0,
                //     "shipping": 0,
                //     "items": products
                // });

                registerEvent('confirm_page', data.total / 100);

            } catch (e) {

            }
        }
    }
    // useEffect(gtagPurchase, [data?.items]);
    //
    // const gtag = () => {
    //     // @ts-ignore
    //     window.dataLayer = window.dataLayer || [];
    //     // @ts-ignore
    //     window.dataLayer.push({
    //         event: 'pageview',
    //         page: {
    //             url: window.location.href,
    //             title: "Thank you"
    //         }
    //     });
    // }
    // useEffect(gtag, []);

    if (!data) {
        return <Fragment/>;
    }

    let needShippingForm = false;
    if (data.data.shipping) {
        if (!data.data.shipping.country) {
            needShippingForm = true;
        }
        if (!data.data.shipping.city) {
            needShippingForm = true;
        }
        if (!data.data.shipping.line1) {
            needShippingForm = true;
        }
        if (!data.data.shipping.postal_code) {
            needShippingForm = true;
        }
        if (!data.data.shipping.state) {
            needShippingForm = true;
        }
    }

    let needContactForm = false;
    if (data.data.contact) {
        if (!data.data.contact.email) {
            needContactForm = true;
        }
        if (!data.data.contact.phone) {
            needContactForm = true;
        }
        if (!data.data.contact.name) {
            needContactForm = true;
        }
    }

    const updateContactInfo = () => {
        if (!!contact) {
            PagesApi.updateOrderContactInfo(
                serial,
                contact,
            ).then(() => {
                getDetails();
                setContactUpdated(true);
            })
        }
    }
    const updateAddressInfo = () => {
        if (!!address) {
            PagesApi.updateOrderAddressInfo(
                serial,
                address,
            ).then(() => {
                getDetails();
                setAddressUpdated(true);
            })
        }
    }

    const currencySymbol = getSymbolFromCurrency(data.data.currency);
    const lang = data.data.lang;

    const totalItems = data.items.filter(p => p.title.indexOf('Sales Tax') === -1);
    const taxItems = data.items.filter(p => p.title.indexOf('Sales Tax') !== -1);
    let taxTotal = 0;
    let taxTitle = '';
    taxItems.forEach(l => {
        taxTitle = l.title.replace('Estimated ', '')
        taxTotal += l.price * l.quantity;
    })

    return (
        <LangProvider lang={data.data.lang}>
            <Fragment>
                <HeaderBackground image={data.data.bg}/>
                <HeaderLogoTitle image={data.data.mainLogoUrl} title={""}/>

                {(contactUpdated || addressUpdated) &&
                    <main id="content">
                        <form className={"form-cart confirm-update-block-success"}>
                            <p className={'text-center'}>The information has been updated successfully!</p>
                        </form>
                    </main>
                }

                {needContactForm && <main id="content">
                    <form className={"form-cart confirm-update-block"}>
                        <CustomerInformation contact={contact} onContactChange={onContactChange}
                                             customHeaderText={<span
                                                 style={{fontSize: 26, color: '#FF0000', fontWeight: 'bold'}}>!PLEASE MAKE SURE ALL FIELDS MARKED IN RED ARE FILLED IN!</span>}
                                             customSubHeaderText={<span style={{fontSize: 20, color: '#000'}}>
                            If you do not do this, <strong>your order is highly likely to not reach you.</strong><br/>
                            We do not take any responsibility for lost orders that are not our fault, so <strong>please take the time to fill out all of the fields correctly!</strong>
                        </span>}/>

                        <p>
                            <button type="button" onClick={updateContactInfo}>Update information</button>
                        </p>
                    </form>
                </main>}

                {needShippingForm && <main id="content">
                    <form className={"form-cart confirm-update-block"}>
                        <DeliveryAddress address={address} onAddressChange={onAddressChange}
                                         disableInit={true}
                                         customHeaderText={<span
                                             style={{fontSize: 26, color: '#FF0000', fontWeight: 'bold'}}>!PLEASE MAKE SURE ALL FIELDS MARKED IN RED ARE FILLED IN!</span>}
                                         customSubHeaderText={<span style={{fontSize: 20, color: '#000'}}>
                            If you do not do this, <strong>your order is highly likely to not reach you.</strong><br/>
                            We do not take any responsibility for lost orders that are not our fault, so <strong>please take the time to fill out all of the fields correctly!</strong>
                        </span>}/>

                        <p>
                            <button type="button" onClick={updateAddressInfo}>Update information</button>
                        </p>
                    </form>
                </main>}


                <main id="content">
                    <form className={"form-cart module-product"}>
                        <div style={{width: '100%'}}>
                            {/*<div className={"text-center"}><h1>Thank you - Order Completed!</h1></div>*/}
                            <div className={"text-center"}><h2>{texts[lang].youJustGot}</h2></div>
                            <div className={"text-center"}><p
                                className={"m0"}>{texts[lang].orderReceipt}: <strong>{data.data.orderPrefix}-{serial.toUpperCase()}</strong>
                            </p></div>

                            <div className={"text-center"} style={{marginTop: 15}}><p className={"m0"}>See your
                                invoice <a target={"_blank"} style={{textDecoration: 'underline'}}
                                           href={`/order/confirm-pdf/${serial}`}>here</a></p></div>

                            {/*<div className={"text-center"}><h2>Payment Successfully Completed</h2></div>*/}
                            <br/>
                            <br/>
                            <h2><span>{texts[lang].yourProduct}</span></h2>

                            {/*<nav className="module-nav">*/}
                            <div style={{padding: 10}}>
                                <ul className="list-total">
                                    <li className="header">{texts[lang].item} <span>{texts[lang].amount}</span></li>
                                    {totalItems.map((item, index) => {
                                        const q = !!item.quantity && item.quantity > 1 ? item.quantity + ' x ' : '';
                                        return (<li key={"item-" + index}>{item.title}
                                            <span>{q}{currencySymbol}{item.price.toFixed(2)}</span>
                                        </li>);
                                    })}
                                    {taxTotal > 0 && <li className="strong">{taxTitle}
                                        <span>{currencySymbol}{(taxTotal).toFixed(2)}</span></li>}
                                    <li className="strong">{texts[lang].total}
                                        <span>{currencySymbol}{(data.total / 100).toFixed(2)}</span></li>
                                </ul>
                            </div>
                            {/*</nav>*/}
                        </div>
                    </form>

                    <form className={"form-cart module-product"}>
                        <div style={{width: '100%'}}>
                            <h2><span>{texts[lang].thankYou}</span></h2>

                            <div className={"fs-18"}>
                                <p className={"text-bold"}>{texts[lang].confMail}</p>
                                <p>{texts[lang].confMail2}</p>
                                <p>{texts[lang].confMail3} {data.data.productName},
                                    {texts[lang].confMail4}
                                </p>
                                <p className={"text-bold"}>{texts[lang].confMail5.replace("#PRODUCTNAME#", data.data.productName)}</p>
                            </div>
                        </div>
                    </form>

                    <form className={"form-cart module-product"}>

                        <div className={"text-center"}>
                            <img src={EmailImage} width={150} height={"auto"} alt={""}/>
                        </div>
                        <div>
                            <h3>{texts[lang].gotQuestions}</h3>
                            <p>{texts[lang].needAssistance}<br/>{texts[lang].feelFree} {data.data.email}. {texts[lang].assistant}
                            </p>
                        </div>

                    </form>
                </main>
                <br/>
                <br/>
                <FooterNeedHelpComponent email={data.data.email}/>

            </Fragment>
        </LangProvider>
    )
}
