import React, {Fragment} from "react";
import StripeCheckoutWrapper from "./StripeCheckoutWrapper";
import {usePage} from "../../../../../Provider/PageProvider";

import CreditCardImage from "../../../../../Assets/Stripe_payments.png";
import AmazonImage from "../../../../../Assets/amazon_pay.png";
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {useLang} from "../../../../../Provider/LangProvider";
import AmazonPayWrapper from "./Amazon/AmazonPayWrapper";
import {isMobileOnly} from 'react-device-detect';
import PaypalCheckoutWrapper from "./PaypalCheckoutWrapper";
import PaypalImage from "../../../../../Assets/paypal.png";


import insuranceCopyImage from "../../../../../Assets/insurance_copy.png";
import PadlockImage from "../../../../../Assets/padlock.png";

const isPraytify = () => {
    return window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify2.com';
}

const isLulaSafe = () => {
    return window.location.host === 'checkout.lulasafe.com';
}

const isSecureBlessify = () => {
    return window.location.host === 'secure.blessify.com';
}

const texts: any = {
    en: {
        step4: isPraytify() ? "Step 3" : "Step 4",
        paymentInfo: "Payment Information",
        creditCard: "Credit Card",
        paypal: "PayPal",
        afterClicking: "After clicking \"Place the Order\", you will be redirected to PayPal to complete your purchase securely."
    },
    es: {
        step4: "PASO 4",
        paymentInfo: "INFORMACIÓN DE PAGO",
        creditCard: "Tarjeta de crédito",
        paypal: "PayPal",
        afterClicking: "Después de hacer clic en \"Realizar el pedido\", será redirigido a PayPal para completar su compra de forma segura."
    },
    de: {
        step4: "SCHRITT 4",
        paymentInfo: "ZAHLUNGSINFORMATIONEN",
        creditCard: "Kreditkarte",
        paypal: "PayPal",
        afterClicking: "Nachdem Sie auf \"Bestellung aufgeben\" geklickt haben, werden Sie zu PayPal weitergeleitet, um Ihren Kauf sicher abzuschließen."
    },
}

export default function CheckoutCard() {
    const {registerEvent} = usePageView();
    const {paymentMethod, setPaymentMethod, cardNumberRef, url} = usePage();
    const {lang} = useLang();


    const paymentSwitch = (method: string) => {
        setPaymentMethod(method);
        registerEvent('payment_set_' + method, 1);
    }

    return (
        <Fragment>
            <h2 ref={cardNumberRef}>{texts[lang].step4}: <span>{texts[lang].paymentInfo}</span></h2>
            <ul className="list-payment">
                <li>
                    <input type="radio" id="stripe" name="payment-method" checked={paymentMethod === 'credit'}
                           onClick={() => paymentSwitch('credit')}/> <label
                    htmlFor="stripe">{texts[lang].creditCard}</label>
                    <div className="triple a">
                        {paymentMethod === 'credit' && <StripeCheckoutWrapper active={paymentMethod === 'credit'}/>}
                    </div>
                    <img src={CreditCardImage} width={300} />


                    <div className={"stripe-extra"}>
                        <div className="trust-parent">
                            <div className="inline-block-insurance-flex">
                                <img className="badge" src={insuranceCopyImage} /><span className="guarantee100">You're 100% backed by our 30-day money-back guarantee.</span>
                            </div>
                        </div>
                        <div className="trust-parent">
                            <div className="inline-block-insurance-flex">
                                <img className="badge" src={PadlockImage} /><span className="guarantee100">Payments are securely processed by Stripe (PCI Service Provider Level 1, SSL protected)</span>
                            </div>
                        </div>
                    </div>

                </li>

                {!isSecureBlessify() &&
                <li>
                    <input type="radio" id="paypal-radio" name="payment-method" checked={paymentMethod === 'paypal'}
                           onClick={() => paymentSwitch('paypal')}/>
                           <label htmlFor="paypal-radio">{texts[lang].paypal}</label>
                    {paymentMethod === 'paypal' && <PaypalCheckoutWrapper active={paymentMethod === 'paypal'}/>}
                    <div>
                        <p className={"color-dove"}>
                            {texts[lang].afterClicking}
                        </p>
                    </div>
                    <img src={PaypalImage} alt="" width="88" height="25"/></li>
                }

                {/*<li>*/}
                {/*    <input type="radio" id="amazon_pay" name="payment-method" checked={paymentMethod === 'amazon_pay'}*/}
                {/*           onClick={() => paymentSwitch('amazon_pay')}/> <label htmlFor="amazon_pay">Amazon Pay</label>*/}
                {/*    {paymentMethod === 'amazon_pay' && <AmazonPayWrapper active={paymentMethod === 'amazon_pay'}/>}*/}
                {/*    <div>*/}
                {/*        <p className={"color-dove"}>*/}
                {/*            After clicking Amazon Pay button below, you will be redirected to Amazon to complete your purchase securely*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <img src={AmazonImage} alt="" width="88" height="25"/>*/}
                {/*</li>*/}

            </ul>

        </Fragment>
    )

}
