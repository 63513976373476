import React, {useEffect, useState} from "react";

export default function PlaceOrderOverlay() {
    const [seconds, setSeconds] = useState(5);

    const fireTimer = (_seconds: number) => {
        if (_seconds > 0) {
            setSeconds(_seconds - 1);
            setTimeout(() => fireTimer(_seconds - 1), 1000);
        }
    }
    useEffect(() => {
        fireTimer(5)
    }, []);


    return (
        <div className={"place-order-popover"}>
            <div className={"place-order-popover-container"}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p>Confirming.<br/> {seconds > 0 ? `Please wait ${seconds} seconds...` : 'Please wait'}</p>
            </div>
        </div>
    )
}
