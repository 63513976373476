import React, {Fragment, useEffect, useState} from "react";
import {CartTotalItem, Product, UpsaleCartTotalItem} from "../../../../../Types/Objects";
import UpsaleProduct from "./UpsaleProduct";
import UpsaleCartItem from "./UpsaleCartItem";
import {useCart} from "../../../../../Provider/CartProvider";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {DetailsResponse} from "../../../../../Types/Upsale";
import PagesApi from "../../../../../Service/PagesApiService";
import FooterNeedHelpComponent from "../Footer/FooterNeedHelpComponent";
import HeaderBackground from "../Header/HeaderBackground";
import HeaderLogoTitle from "../Header/HeaderLogoTitle";
import UpsaleCancelButton from "./UpsaleCancelButton";
import {usePageView} from "../../../../../Provider/PageViewProvider";
// import {sha256} from 'js-sha256';
import * as Sentry from "@sentry/react";
import {isMobileOnly} from 'react-device-detect';

// @ts-ignore
import getSymbolFromCurrency from 'currency-symbol-map'
import {LangProvider} from "../../../../../Provider/LangProvider";
import {states} from "../../Form/Fields/StateField";
import AmazonPayButton from "../Checkout/Amazon/AmazonPayButton";

const texts: any = {
    en: {
        upgradeYourOrder: "Upgrade Your Order With One Click. Special Deals For Add‑ons Only Now!",
        submitting: "Submitting...",
        placeTheOrder: "Place the order",
        youHaveSelected: "You Have Selected",
    },
    es: {
        upgradeYourOrder: "Actualice su pedido con un clic. ¡Ofertas especiales para complementos solo ahora!",
        submitting: "Enviar...",
        placeTheOrder: "Realizar el pedido",
        youHaveSelected: "Has seleccionado",
    },
    de: {
        upgradeYourOrder: "Upgraden Sie Ihre Bestellung mit einem Klick. Sonderangebote für Add-Ons erhalten Sie nur jetzt!",
        submitting: "Übermitteln...",
        placeTheOrder: "Bestellung aufgeben",
        youHaveSelected: "Du hast ausgewählt",
    }
}

// @ts-ignore
window.onAmazonCheckoutGet = (sessionId) => {
    const event = new CustomEvent('amznSessionId', {detail: sessionId});
    window.dispatchEvent(event);
}

export default function UpsaleContent() {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('amazonCheckoutSessionId');

    const location = useLocation();

    const {onPageView, registerEvent} = usePageView();

    const [isProcessing, setIsProcessing] = useState(false);
    const {cartItems, changeCartItemQuantity, removeFromCartItem} = useCart();

    const history = useHistory();
    const {serial} = useParams();
    const [data, setData] = useState<DetailsResponse>();
    const getDetails = () => {
        const lHash = window.location.hash ? window.location.hash : '';
        PagesApi.getDetails(serial, lHash).then((res: DetailsResponse) => {
            if (res.success === 1) {
                if (res.data.products.length === 0) {
                    redirectToConfirm();
                } else {
                    setData(res);
                    PagesApi.setFavicon(res.data.favicon);
                }
            }
        })
    }
    useEffect(getDetails, []);

    useEffect(() => {
        if (sessionId && !!data) {
            setTimeout(() => {
                makeOrder({amazonId: sessionId});
            }, 500);
        }
    }, [sessionId, data]);


    const onAmazonSessionId = (e: any) => {
        makeOrder({amazonId: e.detail});
    }

    useEffect(() => {
        window.addEventListener('amznSessionId', onAmazonSessionId);

        return () => {
            window.removeEventListener('amznSessionId', onAmazonSessionId);
        }
    }, [data, cartItems]);

    const gaCheckout = () => {
        if (data && data.data && cartItems.length > 0) {
            const currencyRate = data.data.currency === 'MXN' ? 0.05 : 1;

            try {
                // @ts-ignore
                window.dataLayer.push({
                    'Checkoutversioning': data.data.ganame,
                    'event': 'checkout',
                    'upsell': 'upsell',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': 5, 'option': data.data.ganame},
                            'products': cartItems.filter((_i) => _i.quantity > 0 && _i.product.price > 0).map((_i, index: number) => {
                                return {
                                    'name': _i.product.cartTitle,
                                    'id': _i.product.productId,
                                    'price': _i.product.price * currencyRate,
                                    'category': 'upsell',
                                    'quantity': _i.quantity,
                                    'variant': ''
                                }
                            })
                        }
                    }
                });
            } catch (e) {

            }
        }
    }
    useEffect(gaCheckout, [cartItems])


    const gtagUpsellPurchase = () => {
        // return;
        if (!!data && !!data.data) {
            const currencyRate = data.data.currency === 'MXN' ? 0.05 : 1;

            // let products: any = data.items.filter((_i) => _i.quantity > 0 && _i.price > 0).map((item: CartTotalItem, index: number) => {
            //     return {
            //         'id': item.productId === -1 ? 'shipping' : item.productId,
            //         'name': item.title,
            //         'price': item.price * currencyRate,
            //         'quantity': item.quantity,
            //         'variant': '',
            //         'category': index === 0 ? 'main' : 'other',
            //         'coupon': '',
            //     }
            // })

            let products = cartItems.filter((_i) => _i.quantity > 0 && _i.product.price > 0).map((item: UpsaleCartTotalItem, index: number) => {
                return {
                    'id': item.product.productId === -1 ? 'shipping' : item.product.productId,
                    'name': item.product.cartTitle,
                    'price': item.product.price * currencyRate,
                    'quantity': item.quantity,
                    'variant': '',
                    'category': 'upsell',
                    'coupon': '',
                }
            });

            let total = 0;
            products.map((i: any) => {
                total += i.quantity * i.price;
            })

            try {

                // @ts-ignore
                window.dataLayer.push({
                    'event': 'purchase',
                    'upsell': 'upsell',
                    'ecommerce': {
                        'purchase': {
                            'email': data.data && data.data.contact ? data.data.contact?.email : '',
                            'actionField': {
                                'id': serial,
                                'affiliation': '',
                                'revenue': total,
                                'tax': 0.00,
                                'shipping': 0.00,
                                'coupon': ''
                            },
                            'products': products
                        }
                    }
                });

            } catch (e) {

            }
        }
    }

    const makeOrder = (extraData: any) => {
        console.log('makeOrder', extraData, data);
        setIsProcessing(true);
        if (data) {
            const _cartItems = [...cartItems];
            if (data.data.shipping?.country === 'US') {
                const _state = states.filter(f => f[0] === data.data.shipping?.state);
                if (_state.length > 0) {
                    const tmpTotal = _cartItems && _cartItems.length > 0 ? _cartItems.map(item => item.product.price * item.quantity).reduce((prev, next) => prev + next) : 0;

                    const percent: string = _state[0][1];
                    const percentN: number = parseFloat(percent.replace('%', ''));
                    const _taxValue = Math.round(tmpTotal * percentN) / 100;

                    _cartItems.push({
                        product: {
                            // @ts-ignore
                            productId: 'tax-data',
                            cartTitle: "Estimated Sales Tax (" + percent + ")",
                            price: _taxValue,
                        },
                        quantity: 1,
                    });
                }
            }

            PagesApi.makeUpsaleOrder(data.data.url, _cartItems.map((item: UpsaleCartTotalItem) => {
                return {
                    productId: item.product.productId,
                    title: item.product.cartTitle,
                    price: item.product.price,
                    quantity: item.quantity,
                }
            }), data.paymentMethod, serial, extraData).then((res: any) => {
                if (res.success === 1 && !!res.id) {
                    // gtagUpsellPurchase();
                }
                if (data.paymentMethod === 'amazon') {
                    if (res.redirectUrl) {
                        window.location.href = res.redirectUrl;
                    }
                } else if (data.paymentMethod === 'paypal') {
                    if (res.success === 1) {
                        const links = res.data.result.links;
                        const approveLinks = links.filter((item: any) => item.rel === 'approve');
                        if (approveLinks.length > 0) {
                            window.location.href = approveLinks[0].href;
                        }
                    }
                } else {
                    if (res.success === 1) {
                        redirectToConfirm();
                    }
                }

            });
        }
    }

    const redirectToConfirm = () => {
        history.push('/order/confirm/' + serial);
    }

    const onPageLoad = () => {
        if (data && data.data) {

            onPageView({
                allScripts: data.data.allScripts,
                allScriptsUrl: data.data.allScriptsUrl,
                css: data.data.css,
                favicon: data.data.favicon,
                fontUrl: data.data.fontUrl,
                pageScripts: data.data.upsellScripts,
                pageScriptsUrl: data.data.upsellScriptsUrl,
                title: data.data.title
            }, 'Upsale');

            gtagPurchase();
        }
    }
    useEffect(onPageLoad, [data]);

    const gtagPurchase = () => {
        const roundF = (f: number) => Math.round(f * 100) / 100;

        if (!!data && !!data.data) {
            const currencyRate = data.data.currency === 'MXN' ? 0.05 : 1;

            const products = data.items.filter((_i) => _i.quantity > 0 && _i.price > 0 && _i.title.indexOf('Estimated Sales Tax') === -1).map((item: CartTotalItem, index: number) => {
                return {
                    'id': item.productId === -1 ? 'shipping' : item.productId,
                    'name': item.title,
                    'price': roundF(item.price * currencyRate),
                    'quantity': item.quantity,
                    'variant': '',
                    'category': index === 0 ? 'main' : 'other',
                    'coupon': '',
                }
            });
            let dtTotal = 0;
            products.forEach((p) => {
                dtTotal += p.quantity * p.price;
            });
            try {
                if (location.search) {
                    const params = new URLSearchParams(location.search);
                    if (params && params.get('_r')) {
                        // @ts-ignore
                        window.dataLayer.push({
                            'event': 'purchase',
                            'PurchaseType': '',
                            'ecommerce': {
                                'purchase': {
                                    'email': data.data && data.data.contact ? data.data.contact?.email : '',
                                    'actionField': {
                                        'id': serial,
                                        'affiliation': '',
                                        'revenue': dtTotal,
                                        'tax': 0.00,
                                        'shipping': 0.00,
                                        'coupon': ''
                                    },
                                    'products': products
                                }
                            }
                        });

                        // Sentry.captureMessage("gtagPurchase send " + serial, Sentry.Severity.Info);

                        registerEvent('upsell_page', data.total / 100);

                        history.replace(location.pathname);
                    } else {
                        // Sentry.captureMessage("gtagPurchase ignore 2 " + serial, Sentry.Severity.Info);
                    }
                } else {
                    // Sentry.captureMessage("gtagPurchase ignore " + serial, Sentry.Severity.Info);
                }
            } catch (e) {
                Sentry.captureMessage("gtagPurchase error " + e.message, Sentry.Severity.Error);
            }
        }
    }

    if (!data) {
        return <Fragment/>;
    }

    const currencySymbol = getSymbolFromCurrency(data.data.currency);
    const lang = data.data.lang;
    return (
        <LangProvider lang={data.data.lang}>
            <Fragment>
                <HeaderBackground image={data.data.bg}/>
                <HeaderLogoTitle image={data.data.mainLogoUrl}
                                 title={data.data.upsellTitle ? data.data.upsellTitle : texts[lang].upgradeYourOrder}/>

                <main id="content">

                    {data.data.products.map((i: Product) => {
                        const isInCart = cartItems.filter((p: UpsaleCartTotalItem) => p.product.productId === i.productId).length > 0;
                        return (
                            <UpsaleProduct changeCartItemQuantity={changeCartItemQuantity} isInCart={isInCart}
                                           product={i}
                                           currencySymbol={currencySymbol}
                                           removeFromCartItem={removeFromCartItem}/>)
                    })}

                    {!!cartItems && cartItems.length === 0 &&
                        <UpsaleCancelButton className={"m50"}/>
                    }
                    {cartItems.length > 0 &&
                        <Fragment>
                            <form className="m40">
                                <h2>{texts[lang].youHaveSelected}:</h2>
                                <ul className="list-cart-small">
                                    {cartItems.map((item: UpsaleCartTotalItem) => {
                                        return (
                                            <UpsaleCartItem changeCartItemQuantity={changeCartItemQuantity} item={item}
                                                            removeFromCartItem={removeFromCartItem}
                                                            currencySymbol={currencySymbol}/>
                                        )
                                    })}

                                </ul>
                                {data.data.paymentMethod === 'amazon' ?
                                    <Fragment>
                                        {isMobileOnly ? <AmazonPayButton url={data.data.url} upsaleOrder={serial}/> :
                                            <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                                                <iframe src={`/amazon/button/${data.data.url}`}
                                                        style={{width: 320, height: 50}}></iframe>
                                            </div>
                                        }
                                    </Fragment>
                                    :
                                    <p>
                                        <button type="button"
                                                onClick={() => makeOrder({})}>{isProcessing ? texts[lang].submitting : texts[lang].placeTheOrder}</button>
                                    </p>
                                }
                            </form>
                            <UpsaleCancelButton/>
                        </Fragment>
                    }
                </main>

                <FooterNeedHelpComponent email={data.data.email}/>

            </Fragment>
        </LangProvider>
    )
}
