import Config from "../Constants/Config";
import {Address, Contact} from "../Types/CheckoutTypes";
import {CartTotalItem} from "../Types/Objects";
import Cookies from 'js-cookie';
import {v4 as uuidv4} from 'uuid';

const uuidKey = '_net_uuid';

class PagesApiService {
    constructor() {
        this._token = '';

        const lUuid = localStorage.getItem(uuidKey);
        if (lUuid) {
            this._uuid = lUuid;
        } else {
            this._uuid = uuidv4();
            localStorage.setItem(uuidKey, this._uuid);
        }

        // @ts-ignore
        window.checkoutExternalId = this._uuid;

        // setTimeout(() => {
        //     try {
        //         // @ts-ignore
        //         window.fbq('trackCustom', 'CheckoutExternalId', {}, {external_id: this._uuid});
        //     } catch (e) {
        //
        //     }
        // }, 500);
    }

    private _token: string;
    private _uuid: string;

    set token(value: string) {
        this._token = value;
    }

    defaultRequestOptions = () => {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this._token
            },
        }
    };

    getPage(url: string, selectedProduct: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url, selectedProduct})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/info', requestOptions).then(res => res.json());
    }

    checkDiscount(code: string) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({code})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/checkDiscount', requestOptions).then(res => res.json());
    }

    saveContactForm(url: string, contact: Contact) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url, ...contact, fbc: Cookies.get('_fbc'), fbp: Cookies.get('_fbp'), origin: window.location.origin,})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/saveContactForm', requestOptions).then(res => res.json());
    }

    updateOrderContactInfo(orderId: string, contact: Contact) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({
                orderId,
                ...contact,
            })
        };

        return fetch(Config.backendUrl + '/app/shared/pages/updateOrderContactInfo', requestOptions).then(res => res.json());
    }

    updateOrderAddressInfo(orderId: string, address: Address) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({
                orderId,
                ...address,
            })
        };

        return fetch(Config.backendUrl + '/app/shared/pages/updateOrderAddressInfo', requestOptions).then(res => res.json());
    }

    makeOrder(url: string, contact: Contact, address: Address, items: CartTotalItem[], paymentMethod: any, extraData: any) {
        const urlParams = new URLSearchParams(window.location.search);

        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({
                url, ...contact, ...address,
                items,
                paymentMethod,
                extraData,
                fbc: Cookies.get('_fbc'),
                fbp: Cookies.get('_fbp'),
                externalId: this._uuid,
                referrer: window.document.referrer,
                origin: window.location.origin,
                easy_affiliate: Cookies.get('affiliatly_v3') ? Cookies.get('affiliatly_v3') : Cookies.get('easy_affiliate'),
                sub2: urlParams.get('sub2'),
            })
        };

        return fetch(Config.backendUrl + '/app/shared/pages/makeOrder', requestOptions).then(res => res.json());
    }

    generateButtonAmazon(url: string, isMobile: boolean, upsaleOrder?: string) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({
                url,
                isMobile,
                upsaleOrder,
            })
        };

        return fetch(Config.backendUrl + '/app/shared/pages/generateButtonAmazon', requestOptions).then(res => res.json());
    }

    makeFakeOrder(url: string, contact: Contact, address: Address, items: CartTotalItem[], paymentMethod: any, extraData: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({
                url, ...contact, ...address,
                items,
                paymentMethod,
                extraData,
                fbc: Cookies.get('_fbc'),
                fbp: Cookies.get('_fbp'),
                externalId: this._uuid,
                referrer: window.document.referrer,
                easy_affiliate: Cookies.get('affiliatly_v3') ? Cookies.get('affiliatly_v3') : Cookies.get('easy_affiliate')
            })
        };

        return fetch(Config.backendUrl + '/app/shared/pages/makeFakeOrder', requestOptions).then(res => res.json());
    }

    makeUpsaleOrder(url: string, items: CartTotalItem[], paymentMethod: any, id: string, extraData: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url, items, paymentMethod, id, extraData})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/makeUpsaleOrder', requestOptions).then(res => res.json());
    }

    getDetails(orderId: string, hash?: string) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({orderId, hash})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/getDetails', requestOptions).then(res => res.json());
    }


    setPayment(orderId: string, status: string, paymentInfo: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({orderId, status, paymentInfo})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/setPayment', requestOptions).then(res => res.json());
    }

    setFavicon = (url: string) => {
        const favicon = document.getElementById("favicon");
        // @ts-ignore
        favicon.href = url;
    }

    checkZip = (code: string, country: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({code, country})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/checkZip', requestOptions).then(res => res.json());
    }
}

const PagesApi = new PagesApiService();
export default PagesApi;
