import React, {Fragment, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import PagesApi from "../../../../../../Service/PagesApiService";

export default function AmazonPayConfirm() {
    const {url} = useParams();
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const serial = params.get('amazonCheckoutSessionId');

    const sendData = () => {
        // @ts-ignore
        PagesApi.setPayment(serial, 'paid', {}).then((res: any) => {
            if (res.success === 1) {
                if (res.upsale === 1) {
                    history.push('/order/confirm/' + res.mainOrderSerial + '?_r=1');
                } else {
                    history.push('/order/up/' + serial + '?_r=1');
                }
            } else {
                history.push('/cart/' + res.url);
                alert('Payment error, please contact support');
            }
        });
    }

    useEffect(() => {
        if (serial) {
            sendData()
        }
    }, [serial]);


    return (
        <Fragment/>
    )
}
