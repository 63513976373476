import React, {Fragment} from "react";
import CountDown from "./Components/Header/CountDown";
import Reviews from "./Components/Content/Reviews";
import UpgradeOrder from "./Components/Content/UpgradeOrder/UpgradeOrder";
import OrderQuantity from "./Components/Content/Order/OrderQuantity";
import ListTotal from "./Components/Content/Cart/ListTotal";
import CustomerInformation from "./Components/Form/CustomerInformation";
import DeliveryAddress from "./Components/Form/DeliveryAddress";
import PlaceOrder from "./Components/Content/PlaceOrder";
import CheckoutCard from "./Components/Content/Checkout/CheckoutCard";
import {usePage} from "../../Provider/PageProvider";
import ShippingContainerV2 from "./Components/Content/Shipping/ShippingContainerV2";
import HeaderComponent from "./Components/Header/HeaderComponent";

const isPraytify = () => {
    return window.location.host === 'checkout.praytify.com' || window.location.host === 'checkout.blessify2.com';
}

export default function CheckoutV22() {
    const {selectedProduct} = usePage();
    return (
        <Fragment>
            <CountDown minutes={10}/>

            <HeaderComponent/>

            <main id="content">
                {/*<nav className="module-nav">*/}
                {/*    <FeaturesList/>*/}
                {/*</nav>*/}
                <form action="./" method="post" className="form-cart">
                    <OrderQuantity/>
                    {!!selectedProduct && <Fragment>
                        <CustomerInformation/>
                        {!isPraytify() &&
                        <DeliveryAddress/>
                        }
                        <CheckoutCard/>


                        <UpgradeOrder/>
                        {/*<ShippingContainer className={"module-box inv double small"} wide={false}/>*/}
                        {!isPraytify() &&
                        <ShippingContainerV2/>
                        }
                        <ListTotal/>
                        <PlaceOrder/>
                    </Fragment>}

                    <Reviews/>
                </form>
            </main>

        </Fragment>
    )
}
