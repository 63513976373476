import React, {Fragment, useEffect, useState} from "react";
import {usePage} from "../../../../../Provider/PageProvider";
import {useLang} from "../../../../../Provider/LangProvider";
import Field from "./Field";

const texts: any = {
    en: {
        state: "State / Province",
        defaultErrorText: "This field cannot be left blank"
    },
    es: {
        state: "Estado / provincia",
        defaultErrorText: "Este campo no puede dejarse en blanco"
    },
    de: {
        state: "Staat / Provinz",
        defaultErrorText: "Dieses Feld darf nicht leer gelassen werden"
    },
}

export const states = [

    [
        "Alabama",
        "4%"
    ],
    [
        "Alaska",
        "0%"
    ],
    [
        "Arizona",
        "5.60%"
    ],
    [
        "Arkansas",
        "6.50%"
    ],
    [
        "California",
        "7.25%"
    ],
    [
        "Colorado",
        "2.90%"
    ],
    [
        "Connecticut",
        "6.35%"
    ],
    [
        "Delaware",
        "0%"
    ],
    [
        "District of Columbia",
        "6%"
    ],
    [
        "Florida",
        "6%"
    ],
    [
        "Georgia",
        "4%"
    ],
    [
        "Guam",
        "4%"
    ],
    [
        "Hawaii",
        "4.17%"
    ],
    [
        "Idaho",
        "6%"
    ],
    [
        "Illinois",
        "6.25%"
    ],
    [
        "Indiana",
        "7%"
    ],
    [
        "Iowa",
        "6%"
    ],
    [
        "Kansas",
        "6.50%"
    ],
    [
        "Kentucky",
        "6%"
    ],
    [
        "Louisiana",
        "4.45%"
    ],
    [
        "Maine",
        "5.50%"
    ],
    [
        "Maryland",
        "6%"
    ],
    [
        "Massachusetts",
        "6.25%"
    ],
    [
        "Michigan",
        "6%"
    ],
    [
        "Minnesota",
        "6.88%"
    ],
    [
        "Mississippi",
        "7%"
    ],
    [
        "Missouri",
        "4.23%"
    ],
    [
        "Montana",
        "0%"
    ],
    [
        "Nebraska",
        "5.50%"
    ],
    [
        "Nevada",
        "6.85%"
    ],
    [
        "New Hampshire",
        "0%"
    ],
    [
        "New Jersey",
        "6.63%"
    ],
    [
        "New Mexico",
        "5.13%"
    ],
    [
        "New York",
        "4%"
    ],
    [
        "North Carolina",
        "4.75%"
    ],
    [
        "North Dakota",
        "5%"
    ],
    [
        "Ohio",
        "5.75%"
    ],
    [
        "Oklahoma",
        "4.50%"
    ],
    [
        "Oregon",
        "0%"
    ],
    [
        "Pennsylvania",
        "6%"
    ],
    [
        "Puerto Rico",
        "10.50%"
    ],
    [
        "Rhode Island",
        "7%"
    ],
    [
        "South Carolina",
        "6%"
    ],
    [
        "South Dakota",
        "4%"
    ],
    [
        "Tennessee",
        "7%"
    ],
    [
        "Texas",
        "6.25%"
    ],
    [
        "Utah",
        "5.95%"
    ],
    [
        "Vermont",
        "6%"
    ],
    [
        "Virginia",
        "5.30%"
    ],
    [
        "Washington",
        "6.50%"
    ],
    [
        "West Virginia",
        "6%"
    ],
    [
        "Wisconsin",
        "5%"
    ],
    [
        "Wyoming",
        "4%"
    ]
];

interface Props {
    address?: any,
    onAddressChange?: (key: string, val: string) => void,
}

export default function StateField(props: Props) {
    const {address, onAddressChange} = usePage();
    const {lang} = useLang();
    const [isError, setError] = useState(false);

    const formAddress = props.address ? props.address : address;
    const onFormAddressChange = props.onAddressChange ? props.onAddressChange : onAddressChange;

    const checkError = () => {
        const _state = states.filter(f => f[0] === formAddress?.state);
        if (_state.length === 0) {
            setError(true);
        } else {
            setError(false);
        }
    }

    useEffect(() => {
        if (formAddress?.country === 'US') {
            checkError();
        }
    }, [formAddress]);

    if (!formAddress) {
        return <Fragment/>
    }

    let pClassName = [];
    pClassName.push("focus");
    if (isError) {
        pClassName.push("has-error");
    }

    if (formAddress.country === 'US') {
        const htmlKey = "f-state";

        return (
            <p className={pClassName.join(" ")}>
                <label htmlFor={htmlKey}>{texts[lang].state}</label>
                <select value={formAddress.state} onChange={(e) => onFormAddressChange('state', e.target.value)} id={htmlKey}>
                    <option disabled={true} value={""}>Please select state</option>
                    {states.map((item: string[]) => {
                        return (<option key={'state-' + item[0]} value={item[0]}>{item[0]}</option>)
                    })}
                </select>
                {isError &&
                <label className="error"
                       htmlFor={htmlKey}>{texts[lang].defaultErrorText}</label>}
            </p>
        )
    }

    return <Field val={formAddress.state} onChange={onAddressChange} title={texts[lang].state} fieldKey={"state"}
                  required={true}/>
}
